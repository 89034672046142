import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import klickieLogo from '../assets/klickie-logo.png';
import TimeSlotSelector from '../components/TimeSlotSelector';
import LoginModal from '../components/LoginModal';
import RequestAccessModal from '../components/RequestAccessModal';
import { useLocation } from "react-router-dom";

type Service = {
  service_name: string;
  price: string;
  duration: string;
};

type ProfileData = {
  userId: string;
  name: string;
  services: Service[];
  activity?: string;
  photoUrl?: string;
  availability?: any;
};

type TimeSlot = {
  startTime: string;
  endTime: string;
};

interface NetworkPro {
  userId: string;
  name: string;
  activity?: string;
  photoUrl?: string;
  services?: Service[];
}

const API_URL = process.env.NODE_ENV === 'development'
  ? 'https://api.klickie.me'
  : process.env.REACT_APP_API_URL || 'https://api.klickie.me';

// Add helper for service name formatting
const formatServiceName = (name: string) => {
  return name.split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
    .toUpperCase();
};

// Add this CSS class for booking buttons
const bookingButtonClass = "w-full relative group mb-4";
const bookingButtonInnerClass = "relative w-full bg-[#4895AA] text-white py-3 px-6 rounded-xl font-semibold text-lg hover:bg-[#4895AA]/90 transition-colors";

const Avatar = ({ photoUrl, name, size = 'large' }: { photoUrl?: string, name: string, size?: 'large' | 'small' }) => {
  const sizeClass = size === 'large' ? 'w-32 h-32' : 'w-16 h-16';

  // Use media proxy for Twilio URLs
  const imageUrl = photoUrl?.startsWith('https://api.twilio.com')
    ? `${API_URL}/api/media-proxy?url=${encodeURIComponent(photoUrl)}`
    : photoUrl;

  return (
    <div className={`${sizeClass} relative rounded-full overflow-hidden`}>
      <div className="absolute inset-0 bg-[#FCBF11] rounded-full -z-10 transform scale-110"></div>
      <img
        src={imageUrl || klickieLogo}
        alt={name}
        className="w-full h-full object-cover rounded-full"
        onError={(e) => {
          const target = e.target as HTMLImageElement;
          target.src = klickieLogo;
        }}
      />
    </div>
  );
};

const getUserData = () => {
  try {
    const data = localStorage.getItem('userData');
    return data ? JSON.parse(data) : null;
  } catch (error) {
    console.error('Error parsing userData from localStorage:', error);
    return null;
  }
};

const userData = getUserData() || {};

export default function Preview() {
  const { userId } = useParams();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState<ProfileData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showTimeSelector, setShowTimeSelector] = useState(false);
  const [selectedService, setSelectedService] = useState<Service | null>(null);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [pendingAction, setPendingAction] = useState<'book' | 'cancel' | 'callback' | null>(null);
  const [showRequestAccess, setShowRequestAccess] = useState(false);


  // Get share ID from URL if it exists
  const shareId = searchParams.get('s');
  const location = useLocation();
  const { service, rescheduleReason } = location.state || {}; //
  const [isRescheduling, setIsRescheduling] = useState(false); // Flag

  // Automatically trigger handleServiceClick when service is available
  React.useEffect(() => {
    if (service) {
      console.log(service)
      console.log(rescheduleReason)
      handleServiceClick(service?.serviceDetails);
      if (rescheduleReason) {
        setIsRescheduling(true)
      }
    }
  }, [service, rescheduleReason]); // Include rescheduleReason in dependency array if needed


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/profile/${userId}`);
        console.log('=== PROFILE RESPONSE ===');
        console.log('Full response:', response);
        console.log('Profile data:', response.data);
        console.log('Photo URL:', response.data.photoUrl);

        // Ensure we have all required fields from the new API structure
        if (!response.data.name) {
          throw new Error('Invalid profile data: missing name');
        }

        setData(response.data);
      } catch (err) {
        console.error('Error fetching profile:', err);
        setError(err instanceof Error ? err.message : 'Failed to load profile');
      } finally {
        setLoading(false);
      }
    };

    if (userId) {
      fetchData();
    }
  }, [userId]);

  const handleLogin = async (phoneNumber: string) => {
    try {
      // After successful login, check if there was a pending action
      if (pendingAction === 'book' && selectedService) {
        // Just show the time selector after login
        setShowTimeSelector(true);
      } else if (pendingAction === 'cancel') {
        handleCancelBooking();
      } else if (pendingAction === 'callback') {
        handleCallbackRequest();
      }
      setPendingAction(null);
    } catch (error) {
      console.error('Error after login:', error);
      setError('Failed to complete action');
    }
  };

  const handleRequestConnection = async (phoneNumber: string) => {
    try {
      await axios.post(`${API_URL}/api/request-connection`, {
        proId: userId,
        customerPhone: phoneNumber
      });

      // Show success message
      alert(`Connection request sent! ✅\n\n${data?.name} will review your request.\n\nYou'll be redirected to your connections.`);

      // Redirect to customer's connections overview
      window.location.href = `/network`;
    } catch (error) {
      console.error('Error requesting connection:', error);
      setError('Failed to request connection');
    }
  };

  const checkUserAndNetwork = async () => {
    const currentUserId = localStorage.getItem('userId');
    if (!currentUserId) {
      return { isLoggedIn: false, isConnected: false };
    }

    try {
      // Check if user exists and is in network
      const networkResponse = await axios.get<NetworkPro[]>(`${API_URL}/api/network/${currentUserId}`);
      const isConnected = networkResponse.data.some((pro: NetworkPro) => pro.userId === userId);

      return {
        isLoggedIn: true,
        isConnected
      };
    } catch (error) {
      console.error('Error checking user status:', error);
      return { isLoggedIn: false, isConnected: false };
    }
  };

  const handleServiceClick = async (service: Service) => {
    console.log('1. Service button clicked:', service);
    console.log(userId, "userId")
    if (!userId) {
      console.log('No userId found, showing error');
      setError('Invalid booking parameters');
      return;
    }

    try {
      // Check user status
      console.log('2. Checking user and network status...');
      const status = await checkUserAndNetwork();
      console.log('3. Network status result:', status);

      if (!rescheduleReason) {
        if (!status.isLoggedIn) {
          console.log('4a. User not logged in, showing login modal');
          setSelectedService(service);
          setPendingAction('book');
          setShowLoginModal(true);
          return;
        }

        if (!status.isConnected) {
          console.log('4b. User not connected, showing request access modal');
          setShowRequestAccess(true);
          return;
        }
      }

      // Show time slot selector for connected users
      console.log('4c. User logged in and connected, showing time selector');
      setSelectedService(service);
      setShowTimeSelector(true);
      console.log('5. States updated:', {
        selectedService: service,
        showTimeSelector: true
      });
    } catch (error) {
      console.error('Error in booking flow:', error);
      setError('Failed to process booking');
    }
  };

  const proceedWithBooking = async (slot: TimeSlot) => {
    if (!userId || !selectedService) {
      setError('Invalid booking parameters');
      return;
    }

    try {
      // Get the stored user ID from localStorage
      const loggedInUserId = localStorage.getItem('userId');
      const userData = JSON.parse(localStorage.getItem('userData') || '{}');

      // Proceed with booking
      try {
        setError(null);
        const response = await axios.post(`${API_URL}/api/book`, {
          userId,
          serviceId: selectedService.service_name,
          startTime: slot.startTime,
          endTime: slot.endTime,
          clientId: loggedInUserId,
          clientName: userData?.name || 'Customer'
        });

        if (response.data.success) {
          // Show success message
          alert(`Booking confirmed! ✅\n\nService: ${selectedService.service_name}\nWith: ${data?.name}\nTime: ${new Date(slot.startTime).toLocaleString()}\n\nYou'll be redirected to your bookings.`);

          // Close the time selector
          setShowTimeSelector(false);

          // Redirect to customer's bookings overview using the server-provided URL
          setTimeout(() => {
            window.location.href = response.data.details.redirectUrl;
          }, 1000);
        } else {
          throw new Error(response.data.error || 'Failed to confirm booking');
        }
      } catch (error: any) {
        console.error('Error confirming booking:', error);
        const errorMessage = error.response?.data?.error || error.message || 'Failed to confirm booking';
        // setError(errorMessage);
        alert(`Booking failed: ${errorMessage}`);
      }
    } catch (error) {
      console.error('Error confirming booking:', error);
      setError('Failed to confirm booking');
    }
  };

  // Function to handle rescheduling logic
  const handleReschedulingTimeSlotConfirm = async (slot: TimeSlot) => {
    if (!userId || !selectedService) {
      setError('Invalid booking parameters');
      return;
    }

    console.log("selectedService", selectedService)
    console.log("service", service)

    try {
      // Get the stored user ID from localStorage
      const loggedInUserId = localStorage.getItem('userId');
      console.log(loggedInUserId, "loggedInUserId")
      const userData = JSON.parse(localStorage.getItem('userData') || '{}');
      console.log(userData, "userData")
      console.log(rescheduleReason, "rescheduleReason")

      // Proceed with booking
      try {
        setError(null);
        // Prepare data for the reschedule request
        const rescheduleData = {
          rescheduleRequestedBy: loggedInUserId,
          reason: rescheduleReason || 'No reason provided',
          requestedByType: service?.requested_by_reschedule,
          rescheduleDate: new Date(slot.startTime).toISOString(), // Use ISO string
          rescheduleStartTime: new Date(slot.startTime).toISOString(), // Use ISO string
          rescheduleEndTime: new Date(slot.endTime).toISOString(), // Use ISO string
        };

        console.log(rescheduleData, "rescheduleData")
        const response = await axios.put(`${API_URL}/api/reschedule-request/${service?.bookingId}`, rescheduleData);

        console.log(response)

        if (response.data && response.data.success) {
          alert(`Rescheduling confirmed! ✅\n\nService: ${selectedService.service_name}\nWith: ${data?.name}\nNew Time: ${new Date(slot.startTime).toLocaleString()}\n\nYou'll be redirected to your bookings.`);
          setShowTimeSelector(false);
          setTimeout(() => {
            window.location.href = response.data.details.redirectUrl;
          }, 1000);
        } else {
          throw new Error(response.data.error || 'Failed to confirm rescheduling');
        }
      } catch (error: any) {
        console.error('Error confirming booking:', error);
        const errorMessage = error.response?.data?.error || error.message || 'Failed to confirm booking';
        // setError(errorMessage);
        alert(`Booking failed: ${errorMessage}`);
      }
    } catch (error) {
      console.error('Error confirming booking:', error);
      setError('Failed to confirm booking');
    }
  };

  const handleTimeSlotConfirm = async (slot: TimeSlot) => {
    if (!userId || !selectedService) {
      setError('Invalid booking parameters');
      return;
    }

    try {
      // Get the stored user ID from localStorage
      const loggedInUserId = localStorage.getItem('userId');
      const userData = JSON.parse(localStorage.getItem('userData') || '{}');

      if (isRescheduling) {
        await handleReschedulingTimeSlotConfirm(slot); // Call rescheduling function
      } else {
        await proceedWithBooking(slot); // Call normal booking function
      }
    } catch (error) {
      console.error('Error handling time slot confirmation:', error);
      setError('Failed to confirm time slot.');
    }
  };

  const handleCancelBooking = async () => {
    try {
      const status = await checkUserAndNetwork();

      if (!status.isLoggedIn) {
        setPendingAction('cancel');
        setShowLoginModal(true);
        return;
      }

      if (!status.isConnected) {
        alert('You need to be connected with this pro first');
        setShowRequestAccess(true);
        return;
      }

      // Get current user ID and ensure it has + prefix
      const currentUserId = localStorage.getItem('userId')!;
      const normalizedUserId = currentUserId.startsWith('+') ? currentUserId : `+${currentUserId}`;

      // Show confirmation message
      alert(`You'll be redirected to your cancellations page where you can manage all your bookings.`);

      // Redirect to customer's cancellations overview with normalized ID
      window.location.href = `/cancellations/${normalizedUserId}`;
    } catch (error) {
      console.error('Error handling cancellation:', error);
      setError('Failed to process cancellation');
    }
  };

  const handleCallbackRequest = async () => {
    try {
      const status = await checkUserAndNetwork();

      if (!status.isLoggedIn) {
        // Show login modal for non-logged in users
        setPendingAction('callback');
        setShowLoginModal(true);
        return;
      }

      if (!status.isConnected) {
        alert('You need to be connected with this pro first');
        setShowRequestAccess(true);
        return;
      }

      const currentUserId = localStorage.getItem('userId')!;
      const response = await axios.post(`${API_URL}/api/request-callback`, {
        userId,
        clientId: currentUserId,
        clientName: data?.name || 'Customer',
        notes: `Callback requested for ${data?.name || data?.activity || 'Service'}`
      });

      if (response.data.success) {
        // Show success message
        alert(`Callback request sent! ✅\n\n${data?.name} will contact you soon.\n\nYou'll be redirected to your callback requests.`);

        // Ensure user ID has + prefix
        const normalizedUserId = currentUserId.startsWith('+') ? currentUserId : `+${currentUserId}`;

        // Redirect to customer's callbacks overview
        window.location.href = `/callbacks/${normalizedUserId}`;
      }
    } catch (error) {
      console.error('Error requesting callback:', error);
      setError('Failed to request callback');
    }
  };

  const handleRequestAccess = async (phoneNumber: string) => {
    try {
      // If we get here, user doesn't exist, send connection request
      await axios.post(`${API_URL}/api/request-connection`, {
        proId: userId,
        customerPhone: phoneNumber
      });

      // Show success message
      alert(`Request sent! ✅\n\n${data?.name} will review your request and share their Klickie with you.\n\nYou'll receive a WhatsApp message when they accept.`);

      setShowRequestAccess(false);
    } catch (error) {
      console.error('Error requesting access:', error);
      setError('Failed to request access');
    }
  };

  const handleBooking = async () => {
    try {
      const status = await checkUserAndNetwork();

      if (!status.isLoggedIn) {
        setPendingAction('book');
        setShowLoginModal(true);
        return;
      }

      if (!status.isConnected) {
        alert('You need to be connected with this pro first');
        setShowRequestAccess(true);
        return;
      }

      // Get current user ID and normalize it
      const currentUserId = localStorage.getItem('userId')!;
      const normalizedUserId = currentUserId.startsWith('+') ? currentUserId : `+${currentUserId}`;

      // Redirect to bookings page with normalized ID
      window.location.href = `/bookings/${normalizedUserId}`;
    } catch (error) {
      console.error('Error handling booking:', error);
      setError('Failed to process booking');
    }
  };

  if (loading) return <div className="flex justify-center items-center min-h-screen">Loading...</div>;
  if (error) return <div className="flex justify-center items-center min-h-screen text-red-500">{error}</div>;
  if (!data || !userId) return <div className="flex justify-center items-center min-h-screen">No profile found</div>;

  return (
    <>
      <div className="min-h-screen bg-[#1E2738] flex items-center justify-center p-4">
        <div className="w-full max-w-md bg-[#1E2738] rounded-3xl p-8 relative">
          {/* Profile Section */}
          <div className="text-center mb-8">
            <div className="flex justify-center mb-6">
              <Avatar
                photoUrl={data.photoUrl}
                name={data.name}
                size="large"
              />
            </div>
            <h1 className="text-[#FFD700] text-3xl font-bold mb-2">{data.name}</h1>
            <div className="text-gray-400 flex items-center justify-center space-x-2">
              <span>{data.activity || 'Service Provider'}</span>
              <span>•</span>
              <span>0 bookings</span>
            </div>
          </div>

          {/* Services */}
          <div className="space-y-4">
            {data.services.map((service, index) => (
              <button
                key={index}
                onClick={() => handleServiceClick(service)}
                className="w-full relative group"
              >
                <div className="absolute inset-0 bg-[#FCBF11] rounded-xl translate-x-1 translate-y-1" />
                <div className="relative w-full bg-[#4895AA] text-white py-3 px-6 rounded-xl font-semibold text-lg hover:bg-[#4895AA]/90 transition-colors">
                  <div className="flex justify-between items-center">
                    <span>{service.service_name.toUpperCase()}</span>
                    <span>€{service.price}</span>
                  </div>
                </div>
              </button>
            ))}

            {/* Request Callback Button */}
            <button
              onClick={handleCallbackRequest}
              className="w-full relative group"
            >
              <div className="absolute inset-0 bg-[#FCBF11] rounded-xl translate-x-1 translate-y-1" />
              <div className="relative w-full bg-[#4895AA] text-white py-3 px-6 rounded-xl font-semibold text-lg hover:bg-[#4895AA]/90 transition-colors">
                <div className="flex items-center justify-center">
                  <span>REQUEST CALLBACK</span>
                </div>
              </div>
            </button>

            {/* Cancel Booking Button */}
            <button
              onClick={handleCancelBooking}
              className="w-full relative group"
            >
              <div className="absolute inset-0 bg-[#FCBF11] rounded-xl translate-x-1 translate-y-1" />
              <div className="relative w-full bg-[#4895AA] text-white py-3 px-6 rounded-xl font-semibold text-lg hover:bg-[#4895AA]/90 transition-colors">
                <div className="flex items-center justify-center">
                  <span>CANCEL BOOKING</span>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>

      {React.Children.toArray([
        showRequestAccess && (
          <RequestAccessModal
            onSubmit={handleRequestAccess}
            onClose={() => setShowRequestAccess(false)}
            onExistingCustomer={() => {
              setShowLoginModal(true);
              setShowRequestAccess(false);
            }}
          />
        ),
        showLoginModal && (
          <LoginModal
            onLogin={handleLogin}
            onClose={() => setShowLoginModal(false)}
          />
        ),
        showTimeSelector &&
        selectedService && (
          <TimeSlotSelector
            userId={userId}
            serviceName={selectedService.service_name}
            duration={selectedService.duration}
            onSelect={handleTimeSlotConfirm}
            onCancel={() => {
              setShowTimeSelector(false);
              setSelectedService(null);
            }}
          />
        ),
      ])}
    </>
  );

}
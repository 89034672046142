import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Calendar, Clock } from 'lucide-react';
import klickieLogo from '../assets/klickie-logo.png';
import { useNavigate } from "react-router-dom";

const API_URL = process.env.NODE_ENV === 'development'
    ? 'https://api.klickie.me'
    : process.env.REACT_APP_API_URL || 'https://api.klickie.me';

interface Booking {
    id: string;
    bookingId: string;
    proId: string;
    proName: string;
    clientId: string;
    clientName: string;
    serviceName: string;
    serviceDetails: {
        service_name: string;
        service_type: string;
        price: number;
        duration: number;
        quota: number;
        frequency: string;
    };
    rescheduleData: {
        reason: string,
        rescheduleStartTime: string,
        requestedByType: string,
        rescheduleDate: string,
        rescheduleRequestedBy: string,
        rescheduleEndTime: string
    };
    startTime: string;
    endTime: string;
    status: string;
    createdAt: {
        _seconds: number;
        _nanoseconds: number;
    };
    activity?: string;
    customerName?: string;
    type?: 'received' | 'made';
    notes?: string;
}

export default function CustomerBookingHistory(): JSX.Element {
    const { userId } = useParams();
    const [bookings, setBookings] = useState<Booking[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [activeTab, setActiveTab] = useState<'upcoming' | 'past'>('upcoming');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedBooking, setSelectedBooking] = useState<Booking | null>(null);
    const [successModalVisible, setSuccessModalVisible] = useState(false);
    const [showRescheduleModal, setShowRescheduleModal] = useState(false);
    const [newDate, setNewDate] = useState<string>('');
    const [rescheduleReason, setRescheduleReason] = useState('');
    const [selectedReason, setSelectedReason] = useState(""); // To hold the selected template or custom message
    const [customMessage, setCustomMessage] = useState(""); // For custom message input


    useEffect(() => {
        const fetchBookings = async () => {
            try {
                const response = await fetch(`${API_URL}/api/user-bookings/${userId}`);
                if (!response.ok) throw new Error('Failed to fetch bookings');
                const data = await response.json();
                setBookings(data);
            } catch (err) {
                setError(err instanceof Error ? err.message : 'Failed to load bookings');
            } finally {
                setLoading(false);
            }
        };
        fetchBookings();
    }, [userId]);

    const formatBookingTime = (booking: Booking) => {
        if (booking && booking?.rescheduleData?.rescheduleStartTime) {
            const rescheduleStart = new Date(booking.rescheduleData.rescheduleStartTime);
            const rescheduleEnd = new Date(booking.rescheduleData.rescheduleEndTime);
            return (
                <span>
                    <span style={{ color: 'orange' }}>Rescheduled:</span>{' '}
                    {rescheduleStart.toLocaleString([], {
                        weekday: 'short',
                        month: 'short',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit'
                    })} - {rescheduleEnd.toLocaleTimeString([], {
                        hour: '2-digit',
                        minute: '2-digit'
                    })}
                </span>
            );
        }

        const start = new Date(booking.startTime);
        const end = new Date(booking.endTime);
        return `${start.toLocaleString([], {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        })} - ${end.toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit'
        })}`;
    };


    const handleCancelBooking = async (selectedBooking: any) => {
        if (!selectedBooking || !selectedBooking.id || !selectedBooking.proName || !selectedBooking.startTime) {
            console.error('Invalid booking data:', selectedBooking);
            alert('Failed to cancel booking. Invalid booking details.');
            return;
        }

        try {
            const response = await fetch(`${API_URL}/api/client-cancel-booking`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    bookingId: selectedBooking.id,
                    proPhoneNumber: selectedBooking.proId,
                }),
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Failed to cancel booking: ${errorText}`);
            }

            const data = await response.json(); // Parse the response body if needed

            // Update the booking status to "canceled"
            setBookings((prevBookings) =>
                prevBookings.map((b) =>
                    b.id === selectedBooking.id ? { ...b, status: 'canceled' } : b
                )
            );

            closeModal();
            setSuccessModalVisible(true); // Show success modal
            setTimeout(() => setSuccessModalVisible(false), 1000); // Hide modal after 2 seconds

        } catch (err) {
            console.error('Error canceling booking:', err);
            alert('Failed to cancel booking. Please try again later.');
        }
    };

    const handleConfirmBooking = async (selectedBooking: any) => {
        if (!selectedBooking || !selectedBooking.id || !selectedBooking.proName || !selectedBooking.startTime) {
            console.error('Invalid booking data:', selectedBooking);
            alert('Failed to confirm booking. Invalid booking details.');
            return;
        }
        try {
            const response = await fetch(`${API_URL}/api/reschedule-confirm/${selectedBooking.id}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' }
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Failed to confirm booking: ${errorText}`);
            }

            const data = await response.json(); // Parse the response body if needed

            // Update the booking status to "confirmed"
            setBookings((prevBookings) =>
                prevBookings.map((b) =>
                    b.id === selectedBooking.id ? { ...b, status: 'confirmed' } : b
                )
            );

            alert('Booking confirmed successfully!'); // Show a success message

        } catch (err) {
            console.error('Error confirming booking:', err);
            alert('Failed to confirm booking. Please try again later.');
        }
    };



    const navigateToReschedulePage = (booking: any, rescheduleReason: any) => {
        if (!booking) {
            console.error("Invalid booking object", booking);
            return;
        }

        // Call handleBookingAgain and pass both booking and reschedule reason
        handleBookingAgain(booking, rescheduleReason);
    };

    const navigate = useNavigate();

    const handleBookingAgain = (service: any, rescheduleReason: any) => {
        if (!service?.proId) {
            console.error("Invalid service object", service);
            return;
        }

        service.requested_by_reschedule = 'client'

        // Navigate to the desired path with state including the reason
        navigate(`/k/${service.proId}`, { state: { service, rescheduleReason } });
    };


    const now = new Date();

    const upcomingBookings = bookings
        .filter(({ startTime, rescheduleData }) =>
            new Date(rescheduleData?.rescheduleStartTime || startTime).getTime() > now.getTime()
        )
        .sort((a, b) =>
            new Date(b.rescheduleData?.rescheduleStartTime || b.startTime).getTime() -
            new Date(a.rescheduleData?.rescheduleStartTime || a.startTime).getTime()
        );

    const pastBookings = bookings
        .filter(({ startTime, rescheduleData }) =>
            new Date(rescheduleData?.rescheduleStartTime || startTime).getTime() <= now.getTime()
        )
        .sort((a, b) =>
            new Date(b.rescheduleData?.rescheduleStartTime || b.startTime).getTime() -
            new Date(a.rescheduleData?.rescheduleStartTime || a.startTime).getTime()
        );


    const BookingCard = ({ booking }: { booking: Booking }) => {

        const isPastBooking = new Date(booking.startTime) <= now;

        return (
            <div className="bg-white/10 rounded-xl p-4 mb-4">
                <div className="flex items-start justify-between">
                    <div className="flex-1">
                        <div className="flex items-center space-x-2 mb-2">
                            <Calendar className="w-4 h-4 text-[#FFD700]" />
                            <span className="text-white font-medium">
                                {formatBookingTime(booking)}
                            </span>
                        </div>
                        {booking.serviceName && (
                            <div className="text-white/80 mb-1">
                                Service: {booking.serviceName}
                            </div>
                        )}
                        {booking.proName && (
                            <div className="text-white/80 mb-1">
                                Pro: {booking.proName}
                            </div>
                        )}
                        {booking.notes && (
                            <div className="text-white/80 mb-1">
                                Notes: {booking.notes}
                            </div>
                        )}
                        <div className="text-sm text-white/60">
                            Status: {booking.status}
                        </div>
                        {/* Buttons for Cancel Booking, Book Again, and Reschedule */}
                        {!isPastBooking && (
                            <div className="mt-2 space-x-2">
                                {/* Cancel Button (Enabled for Confirmed and Pending Bookings) */}
                                <button
                                    className={`px-4 py-2 rounded-lg ${booking.status === 'confirmed' || booking.status === 'pending'
                                        ? 'bg-red-500 text-white hover:bg-red-600 cursor-pointer'
                                        : 'bg-gray-400 text-gray-300 cursor-not-allowed'
                                        }`}
                                    onClick={() => (booking.status === 'confirmed' || booking.status === 'pending') && openModal(booking)}
                                    disabled={booking.status !== 'confirmed' && booking.status !== 'pending'}
                                >
                                    Cancel
                                </button>

                                {/* Confirm Button for Pending Bookings */}
                                {booking?.status === 'pending' && booking?.rescheduleData && (
                                    booking?.rescheduleData?.requestedByType === 'client' ? (
                                        <span className="text-gray-500 italic">Rescheduled</span>
                                    ) : (
                                        <button
                                            className="px-4 py-2 rounded-lg bg-green-500 text-white hover:bg-green-600 cursor-pointer"
                                            onClick={() => handleConfirmBooking(booking)}
                                        >
                                            Confirm
                                        </button>
                                    )
                                )}

                                {/* Reschedule Button (Hidden for Pending Bookings) */}
                                {booking.status !== 'pending' && (
                                    <button
                                        className="px-4 py-2 rounded-lg text-white bg-[#4895AA] hover:bg-[#4895AA]"
                                        onClick={() => {
                                            setShowRescheduleModal(true);
                                            setSelectedBooking(booking); // Set the booking to be rescheduled
                                        }}
                                    >
                                        Reschedule
                                    </button>
                                )}
                            </div>
                        )}

                    </div>
                </div>
            </div>
        );
    };

    const openModal = (booking: Booking) => {
        setSelectedBooking(booking);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedBooking(null);
    };

    return (
        <div className="min-h-screen bg-[#1E2738] flex flex-col items-center p-4">
            <div className="w-full max-w-md space-y-6 py-12">
                {/* {/ Logo and Title /} */}
                <div className="flex flex-col items-center mb-8">
                    <div className="relative w-32 h-32 mb-6">
                        <div className="absolute inset-0 bg-[#FCBF11] rounded-full -z-10 transform scale-110"></div>
                        <img
                            src={klickieLogo}
                            alt="Klickie"
                            className="absolute inset-0 w-full h-full object-contain rounded-full z-10"
                        />
                    </div>
                    <h1 className="text-[#FFD700] text-4xl text-center font-['Klickie_Font',_Arial,_sans-serif] mb-4">
                        Booking's As Customer
                    </h1>
                </div>

                {/* {/ Tab Buttons /} */}
                <div className="flex space-x-4 mb-6">
                    <button
                        onClick={() => setActiveTab('upcoming')}
                        className={`flex-1 py-2 px-4 rounded-xl font-semibold ${activeTab === 'upcoming'
                            ? 'bg-[#4895AA] text-white'
                            : 'bg-white/10 text-white/60'
                            }`}
                    >
                        Upcoming
                    </button>
                    <button
                        onClick={() => setActiveTab('past')}
                        className={`flex-1 py-2 px-4 rounded-xl font-semibold ${activeTab === 'past'
                            ? 'bg-[#4895AA] text-white'
                            : 'bg-white/10 text-white/60'
                            }`}
                    >
                        Past
                    </button>
                </div>

                {/* {/ Bookings List /} */}
                {loading ? (
                    <div className="text-white text-center">Loading...</div>
                ) : error ? (
                    <div className="text-red-500 text-center">{error}</div>
                ) : (
                    <div className="space-y-4">
                        {(activeTab === 'upcoming' ? upcomingBookings : pastBookings).map((booking) => (
                            <BookingCard key={booking.id} booking={booking} />
                        ))}
                        {(activeTab === 'upcoming' ? upcomingBookings : pastBookings).length === 0 && (
                            <div className="text-white/60 text-center">
                                No {activeTab} bookings found
                            </div>
                        )}
                    </div>
                )}

                {/* Modal for Cancel Booking */}
                {isModalOpen && selectedBooking && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                        <div className="bg-white p-6 rounded-lg w-80 max-w-sm text-center">
                            <h2 className="text-xl font-semibold text-black mb-4">
                                Confirm Cancellation
                            </h2>
                            <p className="text-gray-700 mb-4">
                                Are you sure you want to cancel your session with {selectedBooking.proName} on {formatBookingTime(selectedBooking)}?
                            </p>
                            <div className="flex justify-between">
                                <button
                                    className="px-4 py-2 rounded-lg bg-gray-300 hover:bg-gray-400 text-black"
                                    onClick={closeModal}
                                >
                                    No, Keep it
                                </button>
                                <button
                                    className="px-4 py-2 rounded-lg bg-red-500 hover:bg-red-600 text-white"
                                    onClick={() => {
                                        if (selectedBooking) {
                                            handleCancelBooking(selectedBooking);
                                        }
                                    }}
                                >
                                    Yes, Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {/* Success Modal */}
                {successModalVisible && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                        <div className="bg-white p-4 rounded-lg w-80 max-w-sm text-center">
                            <h2 className="text-xl font-semibold text-green-600 mb-2">
                                Booking Canceled
                            </h2>
                            <p className="text-gray-700">Your booking was successfully canceled.</p>
                        </div>
                    </div>
                )}

                {showRescheduleModal && (
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                        <div className="bg-white p-6 rounded-lg shadow-lg w-96">
                            <h2 className="text-lg font-bold mb-4">Confirm Rescheduling</h2>
                            <p className="mb-4">Are you sure you want to reschedule the booking?</p>

                            {/* Reason for Rescheduling */}
                            <div className="mb-4">
                                <label className="font-medium">Select Reschedule Reason:</label>
                                <div className="mt-2">
                                    {/* Radio Buttons for Reschedule Reasons */}
                                    {["Sorry for the inconvenience", "Emergency - Need to reschedule", "Other"].map((reason) => (
                                        <label key={reason} className="flex items-center space-x-4">
                                            <input
                                                type="radio"
                                                value={reason}
                                                checked={selectedReason === reason}
                                                onChange={() => {
                                                    setSelectedReason(reason);
                                                    if (reason !== "Other") setCustomMessage(""); // Clear custom message if not "Other"
                                                }}
                                                className="mr-2"
                                            />
                                            {reason}
                                        </label>
                                    ))}
                                </div>

                                {/* Input for Custom Reason if 'Other' is selected */}
                                {selectedReason === "Other" && (
                                    <div className="mt-2">
                                        <label className="font-medium">Please specify your reason:</label>
                                        <textarea
                                            value={customMessage}
                                            onChange={(e) => setCustomMessage(e.target.value)}
                                            placeholder="Enter your custom reason"
                                            className={`w-full px-4 py-2 border rounded-lg mt-1 ${!customMessage ? 'border-red-500' : ''}`}
                                        />
                                        {!customMessage && (
                                            <p className="text-red-500 text-sm">This field is required.</p>
                                        )}
                                    </div>
                                )}
                            </div>

                            {/* Action Buttons */}
                            <div className="flex space-x-4">
                                <button
                                    className={`px-4 py-2 rounded-lg text-white bg-green-500 hover:bg-green-600 ${(selectedReason === "Other" && !customMessage) || !selectedReason
                                        ? 'opacity-50 cursor-not-allowed'
                                        : ''
                                        }`}
                                    onClick={() => {
                                        if (selectedReason && (selectedReason !== "Other" || customMessage)) {
                                            setShowRescheduleModal(false); // Close modal
                                            navigateToReschedulePage(selectedBooking, selectedReason === "Other" ? customMessage : selectedReason); // Navigate to rescheduling page
                                        }
                                    }}
                                    disabled={
                                        (selectedReason === "Other" && !customMessage) || !selectedReason
                                    }
                                >
                                    Yes, Reschedule
                                </button>
                                <button
                                    className="px-4 py-2 rounded-lg text-gray-700 bg-gray-200 hover:bg-gray-300"
                                    onClick={() => setShowRescheduleModal(false)}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
import React, { JSX, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Share2, Calendar, BookOpen, Clock, Settings, MessageCircle, Users, Image, CloudOff, Clock1, Settings2, SettingsIcon } from 'lucide-react';
import klickieLogo from '../assets/klickie-logo.png';
import axios from 'axios';

interface ActionButton {
    icon: React.ReactNode;
    title: string;
    description: string;
    action: () => void;
}

interface ProfileData {
    name: string;
    photoUrl?: string;
    activity?: string;
}

const API_URL = process.env.NODE_ENV === 'development'
    ? 'https://api.klickie.me'
    : process.env.REACT_APP_API_URL || 'https://api.klickie.me';

export default function WhatNowPro(): JSX.Element {
    console.log('Component rendered');
    const { userId } = useParams();
    const [profileData, setProfileData] = useState<ProfileData | null>(null);

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response = await axios.get(`${API_URL}/api/profile/${userId}`);
                setProfileData(response.data);
                if (response.data.type === 'PRO' && userId) {
                    localStorage.setItem('userId', userId);
                }
            } catch (error) {
                console.error('Error fetching profile:', error);
            }
        };

        if (userId) {
            fetchProfile();
        }
    }, [userId]);

    const handleCalendarSync = () => {
        const width = 500;
        const height = 600;
        const left = window.screen.width / 2 - width / 2;
        const top = window.screen.height / 2 - height / 2;

        window.open(
            `/calendar-sync/${userId}`,
            'Calendar Sync',
            `width=${width},height=${height},left=${left},top=${top}`
        );
    };

    const actionButtons: ActionButton[] = [
        {
            icon: <Share2 className="w-6 h-6" />,
            title: "SHARE YOUR KLICKIE",
            description: "Share your booking link with clients to let them book your services",
            action: () => window.location.href = `/k/${userId}`
        },
        {
            icon: <Users className="w-6 h-6" />,
            title: "VIEW YOUR KLICKIES CONNECTION",
            description: "See all Klickies in your network, manage connections, and discover new opportunities",
            action: () => window.location.href = `/network/${userId}`
        },
        {
            icon: <Calendar className="w-6 h-6" />,
            title: "SYNC CALENDAR",
            description: "Connect your calendar to automatically sync your bookings and availability",
            action: handleCalendarSync
        },
        {
            icon: <BookOpen className="w-6 h-6" />,
            title: "VIEW BOOKINGS AS PRO",
            description: "Check your upcoming appointments and manage your booking history",
            action: () => window.location.href = `/pro-booking-history/${userId}`
        },
        {
            icon: <BookOpen className="w-6 h-6" />,
            title: "VIEW BOOKINGS AS CUSTOMER",
            description: "Check your upcoming appointments and manage your booking history",
            action: () => window.location.href = `/customer-booking-history/${userId}`
        },
        {
            icon: <Clock1 className="w-6 h-6" />,
            title: "VIEW AND MODIFY YOUR PROFILE",
            description: "Update your profile information to keep it up-to-date.",
            action: () => window.location.href = `/onboarding/${userId}`,
        },
        {
            icon: <Settings className="w-6 h-6" />,
            title: "MODIFY YOUR AGENDA",
            description: "Manage your availability and scheduling.",
            action: () => window.location.href = `/schedule-availability/${userId}`,
        },
        {
            icon: <MessageCircle className="w-6 h-6" />,
            title: "SUPPORT",
            description: "Get help or contact our support team",
            action: () => window.location.href = `mailto:support@klickie.me`
        },



    ];
    console.log(actionButtons);

    return (
        <div className="min-h-screen bg-[#1E2738] text-white p-8">
            <div className="max-w-2xl mx-auto">
                {/* Header */}
                <div className="text-center mb-12">
                    <div className="flex justify-center mb-6">
                        <div className="relative">
                            <div className="absolute inset-0 bg-[#FCBF11] rounded-full -z-10 transform scale-110"></div>
                            <div className="w-32 h-32 relative rounded-full overflow-hidden bg-gray-200 flex items-center justify-center z-10">
                                <img
                                    src={profileData?.photoUrl || klickieLogo}
                                    alt={profileData?.name || "Klickie"}
                                    className="w-full h-full object-cover"
                                    onError={(e) => {
                                        const target = e.target as HTMLImageElement;
                                        target.src = klickieLogo;
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <h1 className="text-4xl font-bold mb-4">Welcome to Your Klickie Dashboard!</h1>
                    <p className="text-gray-300 text-lg mb-8">
                        Here's everything you need to manage your business and grow your network.
                        Share your Klickie with clients for easy booking, and connect with other pros
                        to expand your reach.
                    </p>
                    <div className="bg-[#4895AA]/20 p-6 rounded-xl mb-8">
                        <h2 className="text-xl font-semibold mb-3">🌟 Pro Tips:</h2>
                        <ul className="text-left space-y-2 text-gray-300">
                            <li>• Share your Klickie with clients for instant bookings</li>
                            <li>• Pin your Klickie conversation on WhatsApp to keep it at the top of your chat list.</li>
                            <li>• Save Klickie's number as a contact so the name appears instead of the number.</li>
                            <li>• Keep your calendar synced to ensure accurate availability.</li>
                            <li>• Update your profile regularly to showcase your services effectively.</li>
                            <li>• Use the WhatsApp commands for quick and easy actions.</li>
                        </ul>
                    </div>
                </div>

                {/* Action Buttons */}
                <div className="grid gap-4">
                    {actionButtons.map((button, index) => (
                        <button
                            key={index}
                            onClick={button.action}
                            className="w-full relative group"
                        >
                            <div className="absolute inset-0 bg-[#FCBF11] rounded-xl translate-x-1 translate-y-1" />
                            <div className="relative w-full bg-[#4895AA] text-white py-4 px-6 rounded-xl font-semibold hover:bg-[#4895AA]/90 transition-colors">
                                <div className="flex items-start space-x-4">
                                    <div className="bg-white/10 p-3 rounded-lg">
                                        {button.icon}
                                    </div>
                                    <div className="text-left flex-1">
                                        <div className="font-bold text-lg">{button.title}</div>
                                        <div className="text-gray-300 text-sm">{button.description}</div>
                                    </div>
                                </div>
                            </div>
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
}
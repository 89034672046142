import { Country } from '../services/authService';

export const countries: Country[] = [
  { code: 'BE', prefix: '+32' },
  { code: 'NL', prefix: '+31' },
  { code: 'FR', prefix: '+33' },
  { code: 'DE', prefix: '+49' },
  { code: 'GB', prefix: '+44' },
  { code: 'US', prefix: '+1' },
  { code: 'ES', prefix: '+34' },
  { code: 'IT', prefix: '+39' },
  { code: 'PT', prefix: '+351' },
  { code: 'CH', prefix: '+41' },
  { code: 'IN', prefix: '+91' },
]; 
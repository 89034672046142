import { useEffect, useState, useRef, useCallback } from 'react'
import PhoneInput from '../components/PhoneInput'
import IconAnimation from '../components/IconAnimation'
import axios from 'axios'
import countries from "countries"
import sidneyQR from '../assets/https_www_linkedin_com_in_sidneyvalenta_.svg'
import fabianoQR from '../assets/www_linkedin_com_in_fhavo.svg'

type Section = 'before' | 'after' | 'founders'

// Throttle function to limit how often a function can be called
const throttle = (func: Function, limit: number) => {
    let inThrottle: boolean
    return function(this: any, ...args: any[]) {
        if (!inThrottle) {
            func.apply(this, args)
            inThrottle = true
            setTimeout(() => inThrottle = false, limit)
        }
    }
}

export default function Home() {
    const [country, setCountry] = useState('BE')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [isValid, setIsValid] = useState(false)
    const [currentText, setCurrentText] = useState('')
    const [showForm, setShowForm] = useState(false)
    const [showFounders, setShowFounders] = useState(false)
    const [currentSection, setCurrentSection] = useState<Section>('before')
    const [isFormFloating, setIsFormFloating] = useState(false)
    const [lastTypedWord, setLastTypedWord] = useState('')
    const [showLogo, setShowLogo] = useState(true)
    const [logoState, setLogoState] = useState<'initial' | 'float' | 'exit'>('initial')
    const [showContent, setShowContent] = useState(false)
    const [hasStartedTyping, setHasStartedTyping] = useState(false)

    const contentRef = useRef<HTMLDivElement>(null)
    const activeTextRef = useRef<HTMLParagraphElement>(null)
    const containerRef = useRef<HTMLDivElement>(null)
    const foundersRef = useRef<HTMLParagraphElement>(null)
    const formRef = useRef<HTMLDivElement>(null)
    const formPlaceholderRef = useRef<HTMLDivElement>(null)
    const lastScrollPosition = useRef(0)

    const contentBeforeForm = `If you're here it's because we want you to be part of our journey.

The service industry operates on trust and referrals—word-of-mouth is the engine. But today, providers rely on scattered, manual processes that limit growth and scalability. The tools they use weren't built for them, and no one has addressed the core problem.

Klickie is here to change that.

We're creating the infrastructure that the service industry has been missing. The shift is inevitable - services will move from fragmented to connected, from isolated to networked.`

    const contentAfterForm = `Get Klickie by entering your number in the field. Klickie will manage itself the limited try out slots. The window for being first is small, but the impact will be big.

As founders, we're driven by a shared calling to solve problems that others overlook. With over 30 years of combined experience as entrepreneurs, we've built businesses from the ground up and know what it takes to turn a bold vision into reality.

Klickie is more than a company to us—it's the culmination of everything we've learned about innovation, scalability, and impact.

Looking forward to meet you.

Warm regards,`

    // Logo animation sequence
    useEffect(() => {
        if (logoState === 'initial') {
            // After initial animation, start floating
            setTimeout(() => setLogoState('float'), 800);
            
            // After floating for a bit, exit and show content
            setTimeout(() => {
                setLogoState('exit');
                setTimeout(() => {
                    setShowLogo(false);
                    setShowContent(true);
                    if (!hasStartedTyping) {
                        setHasStartedTyping(true);
                    }
                }, 800);
            }, 2000);
        }
    }, [logoState, hasStartedTyping]);

    // Handle scroll for logo reappearance
    useEffect(() => {
        const handleScroll = throttle(() => {
            if (!containerRef.current) return;
            
            const scrollTop = containerRef.current.scrollTop;
            const isScrollingUp = scrollTop < lastScrollPosition.current;
            const threshold = window.innerHeight * 0.25; // 25% of viewport height
            
            if (isScrollingUp && scrollTop < threshold && !showLogo) {
                setShowLogo(true);
                setLogoState('initial');
                setShowContent(false);
            } else if (!isScrollingUp && scrollTop > threshold && showLogo) {
                setLogoState('exit');
                setTimeout(() => {
                    setShowLogo(false);
                    setShowContent(true);
                }, 800);
            }
            
            lastScrollPosition.current = scrollTop;
        }, 100);

        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
            return () => container.removeEventListener('scroll', handleScroll);
        }
    }, [showLogo]);

    // Text typing effect
    useEffect(() => {
        if (!hasStartedTyping) return;

        let currentWordIndex = 0;
        const typingSpeed = 80;
        const fullContent = contentBeforeForm + contentAfterForm;
        const words = fullContent.split(/(\s+)/).filter(Boolean);
        let isMounted = true;

        const typeWord = () => {
            if (!isMounted) return;
            if (currentWordIndex < words.length) {
                const nextText = words.slice(0, currentWordIndex + 1).join('');
                setCurrentText(nextText);
                if (!/^\s+$/.test(words[currentWordIndex])) {
                    setLastTypedWord(words[currentWordIndex]);
                }
                currentWordIndex++;

                if (nextText.length >= contentBeforeForm.length && !showForm) {
                    setShowForm(true);
                    setCurrentSection('after');
                }

                if (currentWordIndex === words.length) {
                    setShowFounders(true);
                    setCurrentSection('founders');
                }

                setTimeout(() => {
                    if (isMounted) typeWord();
                }, typingSpeed);

                requestAnimationFrame(() => {
                    if (!isMounted) return;
                    const activeElement = currentSection === 'founders' ? foundersRef.current : activeTextRef.current;
                    if (activeElement && containerRef.current) {
                        const containerHeight = containerRef.current.offsetHeight;
                        const textHeight = activeElement.offsetHeight;
                        const scrollPosition = activeElement.offsetTop - (containerHeight / 2) + (textHeight / 2);
                        containerRef.current.scrollTop = scrollPosition;
                    }
                });
            }
        };

        setTimeout(() => {
            if (isMounted) typeWord();
        }, 1000);

        return () => {
            isMounted = false;
        };
    }, [hasStartedTyping]);

    useEffect(() => {
        const detectCountry = async () => {
            try {
                const response = await new Promise<{ country: string }>((resolve) => {
                    setTimeout(() => {
                        resolve({ country: 'BE' })
                    }, 1000)
                })
                setCountry(response.country)
            } catch (error) {
                console.error('Error detecting country:', error)
                setCountry('BE')
            }
        }
        detectCountry()
    }, [])

    useEffect(() => {
        const handleScroll = () => {
            if (formPlaceholderRef.current && containerRef.current) {
                const formRect = formPlaceholderRef.current.getBoundingClientRect()
                const containerRect = containerRef.current.getBoundingClientRect()
                const shouldFloat = formRect.top < containerRect.top + 20
                setIsFormFloating(shouldFloat)
            }
        }

        const container = containerRef.current
        if (container) {
            container.addEventListener('scroll', handleScroll)
            return () => container.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newNumber = e.target.value
        setPhoneNumber(newNumber)
        setIsValid(newNumber.length > 0)
    }

    const handleSubmit = async () => {
        try {
            const API_URL = process.env.REACT_APP_API_URL || 'https://api.klickie.me';
            const selectedCountry = countries.find(c => c.code === country);

            if (!selectedCountry) {
                console.error('Invalid country selected:', country);
                alert('Invalid country selected.');
                return;
            }

            const cleanPhoneNumber = phoneNumber.replace(/\D/g, '');
            const fullPhoneNumber = `${selectedCountry.prefix}${cleanPhoneNumber}`;

            const response = await axios.post(`${API_URL}/new-phone-number`, {
                phoneNumber: cleanPhoneNumber,
                country,
                fullPhoneNumber
            });

            if (response.data.success) {
                setPhoneNumber('');
                alert('Great! Check your WhatsApp for next steps.');
            } else {
                throw new Error(response.data.message || 'Unknown error occurred');
            }
        } catch (error: any) {
            console.error('Error submitting phone number:', error);
            const errorMessage = error.response?.data?.message || error.message || 'Failed to submit phone number';
            alert(`Error: ${errorMessage}. Please try again.`);
        }
    };

    const renderParagraphs = (text: string, section: Exclude<Section, 'founders'>) => {
        if (!text) return null;

        // Split content based on section
        const content = section === 'before' 
            ? text.slice(0, Math.min(text.length, contentBeforeForm.length))
            : text.slice(contentBeforeForm.length);

        if (!content) return null;
        
        // Split into paragraphs and filter out empty ones
        const paragraphs = content.split('\n\n').filter(p => p.trim());
        
        return (
            <div className="space-y-12">
                {paragraphs.map((paragraph, index) => {
                    const isLastParagraph = index === paragraphs.length - 1;
                    const showCursor = isLastParagraph && section === currentSection;
                    // Split into words and spaces
                    const words = paragraph.split(/(\s+)/).filter(Boolean);
                    const isBoldParagraph = paragraph.trim() === "Klickie is here to change that.";
                    
                    return (
                        <p 
                            key={index}
                            ref={showCursor ? activeTextRef : null}
                            className={`${
                                isBoldParagraph
                                    ? "text-[32px] leading-[1.3] font-bold" 
                                    : index === 0 && section === 'before'
                                        ? "text-[32px] leading-[1.3]"
                                        : ""
                            }`}
                        >
                            {words.map((word, wordIndex) => {
                                const isJustTyped = word === lastTypedWord;
                                const isTyped = currentText.length >= content.indexOf(word) + word.length;
                                const isSpace = /^\s+$/.test(word);
                                
                                return (
                                    <span 
                                        key={wordIndex} 
                                        className={`${!isSpace && isJustTyped ? 'word-appear' : ''} ${isTyped ? 'word-visible' : ''}`}
                                        style={{ whiteSpace: 'pre' }}
                                    >
                                        {word}
                                    </span>
                                );
                            })}
                        </p>
                    );
                })}
            </div>
        );
    }

    return (
        <div className="fixed inset-0 bg-[#f7f7f7] overflow-hidden">
            <div 
                ref={containerRef}
                className="absolute inset-0 overflow-auto scroll-smooth"
            >
                <div className="min-h-full flex items-center">
                    <div className="w-full">
                        <div className="max-w-[600px] mx-auto px-6">
                            <main className="relative py-[50vh]">
                                {showLogo && (
                                    <div className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 pointer-events-none">
                                        <IconAnimation 
                                            onComplete={() => {
                                                setLogoState('exit');
                                                setTimeout(() => {
                                                    setShowLogo(false);
                                                    setShowContent(true);
                                                    if (!hasStartedTyping) {
                                                        setHasStartedTyping(true);
                                                    }
                                                }, 800);
                                            }}
                                            className="transform scale-150"
                                        />
                                    </div>
                                )}

                                <div className={`[&_p]:text-[21px] [&_p]:leading-[1.5] transition-opacity duration-500 ${!showContent ? 'opacity-0' : 'opacity-100'}`} ref={contentRef}>
                                    {renderParagraphs(currentText, 'before')}
                                    
                                    {/* Form placeholder */}
                                    {showForm && (
                                        <div ref={formPlaceholderRef} className="my-12 h-[120px]">
                                            {!isFormFloating && (
                                                <div className="max-w-md mx-auto">
                                                    <PhoneInput
                                                        country={country}
                                                        setCountry={setCountry}
                                                        phoneNumber={phoneNumber}
                                                        handlePhoneChange={handlePhoneChange}
                                                        isValid={isValid}
                                                        handleSubmit={handleSubmit}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    
                                    {renderParagraphs(currentText, 'after')}

                                    {showFounders && (
                                        <div className="mt-12 opacity-0 animate-fadeIn">
                                            <div className="flex gap-8 mb-4">
                                                <img src={sidneyQR} alt="Sidney Valenta LinkedIn" className="w-24 h-24" />
                                                <img src={fabianoQR} alt="Fabiano LinkedIn" className="w-24 h-24" />
                                            </div>
                                            <p ref={foundersRef} className="text-[18px] mt-4">
                                                Co-founders of Klickie
                                                {currentSection === 'founders' && (
                                                    <span className="inline-block w-[3px] h-[1.2em] bg-black ml-1 animate-pulse shadow-[0_0_8px_rgba(0,0,0,0.5)] shadow-black"></span>
                                                )}
                                            </p>
                                        </div>
                                    )}
                                </div>

                                {/* Floating form */}
                                {showForm && isFormFloating && (
                                    <div 
                                        ref={formRef}
                                        className="fixed top-0 left-0 right-0 z-50 bg-[#f7f7f7]/95"
                                    >
                                        <div className="max-w-[600px] mx-auto px-6">
                                            <div className="max-w-md mx-auto">
                                                <PhoneInput
                                                    country={country}
                                                    setCountry={setCountry}
                                                    phoneNumber={phoneNumber}
                                                    handlePhoneChange={handlePhoneChange}
                                                    isValid={isValid}
                                                    handleSubmit={handleSubmit}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </main>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Minus, Plus } from "lucide-react";

// Define the Service interface
interface Service {
  service_name: string;
  service_type: 'Private';
  price: number;
  duration: number;
  quota: number;
  frequency: 'One-time' | 'Recurring';
}



const InputWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div className="relative mb-4">
    <div className="absolute inset-0 bg-white rounded-xl z-10"></div>
    <div className="absolute inset-0 border-2 border-[#4895AA] rounded-xl z-20"></div>
    <div className="absolute -right-[4px] -bottom-[4px] left-0 top-0 bg-[#FCBF11] rounded-xl z-0 translate-y-[2px] translate-x-[2px]"></div>
    {children}
  </div>
);

// Add predefined activities
const PREDEFINED_ACTIVITIES = [
    'Dog Walker',
    'Babysitter',
    'Padel Teacher',
    'Lawyer',
    'Plumber',
    'Electrician',
    'Tennis Teacher',
    'Piano Teacher',
    'Personal Trainer',
    'Yoga Instructor',
    'Language Teacher',
    'Math Tutor',
    'Swimming Instructor',
    'Life Coach',
    'Massage Therapist',
    'Guitar Teacher',
    'Dance Instructor',
    'Football Coach',
    'Basketball Coach',
    'Fitness Trainer',
    'Nutritionist',
    'Golf Instructor',
    'Boxing Coach',
    'Meditation Guide',
    'Art Teacher'
];

const OnboardingPro: React.FC = () => {

    const API_URL =
          process.env.NODE_ENV === "development"
            ? "https://api.klickie.me"
            : process.env.REACT_APP_API_URL || "https://api.klickie.me";

  const { userId } = useParams() as { userId: string };
  const [filteredActivities, setFilteredActivities] = useState<string[]>([])
  const [showSuggestions, setShowSuggestions] = useState(false)
  // State variables
  const [activity, setActivity] = useState("");
  const [services, setServices] = useState<Service[]>([
    {
      service_name: "",
      service_type: "Private",
      price: 0,
      duration: 60,
      quota: 1,
      frequency: "" as "One-time" | "Recurring",
    },
  ]);
  const [profileName, setProfileName] = useState('')
  // const [errors, setErrors] = useState<string[]>([]);
  const [errors, setErrors] = useState<{ [key: number]: { [field: string]: string } }>({});

  const [isNewUser, setIsNewUser] = useState(true); // New user check
  

  // Fetch existing user data
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        
        const response = await axios.get(`${API_URL}/api/profile/${userId}`);
        const userData = response.data;
        console.log('User data:', userData);
        if (userData?.activity) {
          setActivity(userData.activity);
        }

        if (userData?.profileName) {
          setProfileName(userData.profileName);
        } else if (userData?.activity) {
          setProfileName(`${userData.activity} lessons`);
        }

        if (userData?.services?.length > 0) {
          setServices(userData.services);
          setIsNewUser(false); // Mark as existing user
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [userId]);

  const handleActivityChange = (input: string) => {
    setActivity(input);
    
     // Clear activity-related error
  if (input.trim()) {
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      // Remove activity error if it exists in any service
      Object.keys(newErrors).forEach((key) => {
        if (newErrors[Number(key)]["activity"] === "Please enter your activity.") {
          delete newErrors[Number(key)]["activity"];
          // Remove the service entry if it has no more errors
          if (Object.keys(newErrors[Number(key)]).length === 0) {
            delete newErrors[Number(key)];
          }
        }
      });
      return newErrors;
    });
  }
  
    if (input.length > 0) {
        const filtered = PREDEFINED_ACTIVITIES.filter(act => 
            act.toLowerCase().includes(input.toLowerCase())
        );
        setFilteredActivities(filtered);
        setShowSuggestions(true);
    } else {
        setFilteredActivities([]);
        setShowSuggestions(false);
    }
};

const handleActivitySelect = (selectedActivity: string) => {
  setActivity(selectedActivity);
  setProfileName(`${selectedActivity} lessons`);
  setShowSuggestions(false);
};


  // Validate the form inputs
  const validateForm = () => {
    const newErrors: { [key: number]: { [field: string]: string } } = {};
    let isValid = true;
  
    if (!activity.trim()) {
      isValid = false;
    }
  
    services.forEach((service, index) => {
      const serviceErrors: { [field: string]: string } = {};
  
      if (!service.service_name.trim()) {
        serviceErrors.service_name = "Please enter a service name.";
        isValid = false;
      }
      if (service.price <= 0) {
        serviceErrors.price = "Please enter a valid price.";
        isValid = false;
      }
      if (service.duration <= 30) {
        serviceErrors.duration = "Please enter a valid duration.";
        isValid = false;
      }
  
      if (Object.keys(serviceErrors).length > 0) {
        newErrors[index] = serviceErrors;
      }
    });
  
    setErrors(newErrors);
    return isValid;
  };
  

  // Handle adding a new service
  const addService = () => {
    setServices([
      ...services,
      {
        service_name: "",
        service_type: "Private",
        price: 0,
        duration: 60,
        quota: 1,
        frequency: "" as "One-time" | "Recurring",
      },
    ]);
  };

  // Handle removing a service
  const removeService = (index: number) => {
    if (services.length > 1) {
      setServices(services.filter((_, i) => i !== index));
    }
  };

  // Handle changes in a service field
  const handleServiceChange = (
    index: number,
    field: keyof Service,
    value: string | number
  ) => {
    const updatedServices = [...services];
    updatedServices[index] = { ...updatedServices[index], [field]: value };
    setServices(updatedServices);

     // Dynamically clear relevant errors
  
  setErrors((prevErrors) => {
    const newErrors = { ...prevErrors };
    delete newErrors[index];
    return newErrors;
  });

  //  // Dynamically clear the relevant error for the specific field and index
  //  setErrors((prevErrors) => {
  //   const newErrors = { ...prevErrors };

  //   if (newErrors[index]?.[field]) {
  //     delete newErrors[index][field];

  //     // If no other errors exist for this service, remove the entry
  //     if (Object.keys(newErrors[index]).length === 0) {
  //       delete newErrors[index];
  //     }
  //   }

  //   return newErrors;
  // });
  };

  const sanitizeData = (data: any) => {
    return Object.fromEntries(Object.entries(data).filter(([_, value]) => value !== undefined));
  };
  
  // Handle form submission for new users
  const handleSubmit = async () => {
    if (!validateForm()) return;
  
    try {
      const responses = await axios.get(`${API_URL}/api/profile/${userId}`);
      const userData = responses.data;
  
      const profileData = sanitizeData({
        userId,
        activity,
        profileName: profileName || `${activity} lessons`, // Default to activity-based name
        services,
      });
  
      const response = await axios.post(`${API_URL}/api/profile`, profileData);
      if (!response.data.success) {
        throw new Error(response.data.error || "Failed to save profile.");
      }
  
      // Redirect to WhatsApp
      window.location.href =
        "https://api.whatsapp.com/send/?phone=3197010257400&type=phone_number&app_absent=0";
    } catch (error: any) {
      console.error("Error submitting profile:", error);
      alert(error.response?.data?.error || "Error saving profile. Please try again.");
    }
  };
  
  

  // Handle updating profile for existing users
  const handleUpdate = async () => {
    if (!validateForm()) return;

    try {

          const responses = await axios.get(`${API_URL}/api/profile/${userId}`);
        const userData = responses.data;
        console.log('User data:', userData);

      const profileData = { userId, activity, profileName: userData.profileName, services };

      const response = await axios.post(`${API_URL}/api/update-profile`, profileData); 
      if (!response.data.success) {
        throw new Error(response.data.error || "Failed to update profile.");
      }

      alert("Profile updated successfully!");
    } catch (error: any) {
      console.error("Error updating profile:", error);
      alert(error.response?.data?.error || "Error updating profile. Please try again.");
    }
    window.location.href = `/what-now/${userId}`;
  };

  return (
    <div className="min-h-screen bg-[#1E2738] flex flex-col items-center p-4">
      <div className="w-full max-w-md space-y-6 py-12">
        <h1 className="text-[#FFD700] text-4xl mb-8 font-['Klickie_Font',_Arial,_sans-serif] text-center">
          Let's get you started
        </h1>

        {/* Activity input */}
        <InputWrapper>
        <div className="relative">
          <input
            type="text"
            value={activity}
            onChange={(e) => handleActivityChange(e.target.value)}
            placeholder="What's your activity? (e.g., Padel lessons)"
            className="relative z-30 w-full h-[50px] px-4 text-left border-0 rounded-xl focus:ring-0 bg-transparent font-['Good_Headline_Pro_Cond',_sans-serif] text-xl"
          />
           {showSuggestions && filteredActivities.length > 0 && (
                            <div className="absolute z-40 w-full mt-1 bg-white rounded-xl shadow-lg max-h-60 overflow-auto">
                                {filteredActivities.map((suggestion, index) => (
                                    <div
                                        key={index}
                                        className="px-4 py-2 cursor-pointer hover:bg-gray-100 text-gray-700"
                                        onClick={() => handleActivitySelect(suggestion)}
                                    >
                                        {suggestion}
                                    </div>
                                ))}
                            </div>
                        )}
          </div>
        </InputWrapper>

        {/* Service inputs */}
        {services.map((service, index) => (
          <div key={index} className="bg-[#1A202C] p-4 rounded-xl space-y-4">
            <div className="flex items-center justify-between">
              <h2 className="text-white text-xl font-['Good_Headline_Pro_Cond',_sans-serif]">
                Service {index + 1}
              </h2>
              {index > 0 && (
                <button
                  onClick={() => removeService(index)}
                  className="text-white hover:text-red-500"
                >
                  <Minus className="h-5 w-5" />
                </button>
              )}
            </div>

            <InputWrapper>
              <input
                type="text"
                value={service.service_name}
                onChange={(e) =>
                  handleServiceChange(index, "service_name", e.target.value)
                }
                placeholder="Service name (e.g., Private Padel Lesson)"
                className="relative z-30 w-full h-[50px] px-4 text-left border-0 rounded-xl focus:ring-0 bg-transparent font-['Good_Headline_Pro_Cond',_sans-serif] text-xl"
              />
            </InputWrapper>
            {errors[index]?.service_name && (
      <p className="text-red-500 text-sm">{errors[index].service_name}</p>
    )}

            <div className="grid grid-cols-1 gap-1">
            <InputWrapper>
            <div className="relative flex items-center bg-transparent rounded-xl">
              <input
                type="number"
                value={service.price || ""}
                onChange={(e) =>
                  handleServiceChange(index, "price", parseFloat(e.target.value) || 0)
                }
                placeholder="Price"
                className="relative z-30 w-full h-[50px] px-4 text-left border-0 rounded-xl focus:ring-0 bg-transparent font-['Good_Headline_Pro_Cond',_sans-serif] text-xl"
                
              />
              <span className="absolute right-4 text-gray-500 font-['Good_Headline_Pro_Cond',_sans-serif] text-xl z-40">€</span>
                </div>
            </InputWrapper>
            {errors[index]?.price && (
        <p className="text-red-500 text-sm">{errors[index].price}</p>
      )}

            <InputWrapper>
            <div className="relative flex items-center bg-transparent rounded-xl">
              <input
                type="number"
                value={service.duration || ""}
                onChange={(e) =>
                  handleServiceChange(index, "duration", parseInt(e.target.value) || 60)
                }
                placeholder="Duration"
                className="relative z-30 w-full h-[50px] px-4 text-left border-0 rounded-xl focus:ring-0 bg-transparent font-['Good_Headline_Pro_Cond',_sans-serif] text-xl"
              />
              <span className="absolute right-4 text-gray-500 font-['Good_Headline_Pro_Cond',_sans-serif] text-xl z-40">min</span>
              </div>
            </InputWrapper>
            {errors[index]?.duration && (
        <p className="text-red-500 text-sm">{errors[index].duration}</p>
      )}
            </div>
            <div className="relative">
                            <div className="mb-2 text-gray-400 text-sm">
                                Choose how clients can book your service. You can offer one-time sessions or recurring appointments (coming soon).
                            </div>
                            <select
                                value={service.frequency}
                                onChange={(e) => handleServiceChange(index, 'frequency', e.target.value)}
                                className="w-full h-[50px] px-4 text-left border-0 rounded-xl focus:ring-0 bg-white font-['Good_Headline_Pro_Cond',_sans-serif] text-xl appearance-none"
                            >
                                <option value="" disabled>Choose how clients can book this service</option>
                                <option value="One-time">One-time Session - Clients book individual appointments</option>
                                <option value="Recurring" disabled>Recurring Sessions - Clients book regular weekly/monthly appointments (Coming Soon)</option>
                            </select>
                        </div>
          </div>
          
        ))}

        <button
          onClick={addService}
          className="w-full h-[50px] bg-[#4895AA] hover:bg-[#4895AA]/90 text-white rounded-xl font-['Good_Headline_Pro_Cond',_sans-serif] text-xl flex items-center justify-center"
        >
          <Plus className="h-5 w-5 mr-2" />
          Add a Service
        </button>

        {/* Conditional buttons */}
        {isNewUser ? (
          <button
            onClick={handleSubmit}
            className="w-full h-[50px] bg-[#FFD700] text-black rounded-xl font-['Good_Headline_Pro_Cond',_sans-serif] text-xl"
          >
            Confirm and go to WhatsApp
          </button>
        ) : (
          <button
            onClick={handleUpdate}
            className="w-full h-[50px] bg-[#4895AA] text-white rounded-xl font-['Good_Headline_Pro_Cond',_sans-serif] text-xl"
          >
            Update
          </button>
        )}
      </div>

      {/* {errors.length > 0 && (
        <div className="w-full max-w-md mt-6 text-red-500 space-y-2">
          {errors.map((error, index) => (
            <p key={index} className="text-sm">
              {error}
            </p>
          ))}
        </div>
      )} */}
    </div>
  );
};

export default OnboardingPro;

import { useEffect, useRef } from 'react';
import gsap from 'gsap';

interface IconAnimationProps {
    onComplete?: () => void;
    className?: string;
}

export default function IconAnimation({ onComplete, className = '' }: IconAnimationProps) {
    const svgRef = useRef<SVGSVGElement>(null);
    const racketRef = useRef<SVGPathElement>(null);
    const ballRef = useRef<SVGCircleElement>(null);
    const babyRef = useRef<SVGPathElement>(null);
    const hammerRef = useRef<SVGPathElement>(null);

    useEffect(() => {
        if (!svgRef.current) return;

        const tl = gsap.timeline({
            onComplete: () => onComplete?.(),
            defaults: { ease: "power2.inOut" }
        });

        // Initial setup
        gsap.set([racketRef.current, ballRef.current, babyRef.current, hammerRef.current], { autoAlpha: 0 });

        // Tennis racket and ball sequence
        tl.to(racketRef.current, { 
            autoAlpha: 1,
            duration: 0.3
        })
        .to(ballRef.current, {
            autoAlpha: 1,
            duration: 0.1
        })
        .to(ballRef.current, {
            x: "+=20",
            duration: 0.2,
            ease: "power4.in"
        })
        .to([racketRef.current, ballRef.current], {
            autoAlpha: 0,
            duration: 0.2
        });

        // Baby face sequence
        tl.to(babyRef.current, {
            autoAlpha: 1,
            duration: 0.3
        })
        .to(babyRef.current, {
            autoAlpha: 0,
            duration: 0.2
        });

        // Hammer sequence
        tl.to(hammerRef.current, {
            autoAlpha: 1,
            duration: 0.2
        })
        .to(hammerRef.current, {
            rotation: 30,
            transformOrigin: "bottom",
            duration: 0.15,
            yoyo: true,
            repeat: 1
        });

    }, [onComplete]);

    return (
        <svg 
            ref={svgRef}
            viewBox="0 0 100 100"
            className={`w-32 h-32 ${className}`}
        >
            {/* Tennis Racket */}
            <path
                ref={racketRef}
                d="M30 20 A20 30 0 1 1 70 20 A20 30 0 1 1 30 20 M50 50 L50 80"
                fill="none"
                stroke="#4895AA"
                strokeWidth="4"
            />
            
            {/* Ball */}
            <circle
                ref={ballRef}
                cx="20"
                cy="35"
                r="5"
                fill="#4895AA"
            />

            {/* Baby Face - Simple, timeless design */}
            <path
                ref={babyRef}
                d="M25 40 A25 25 0 1 1 75 40 A25 25 0 1 1 25 40 M40 35 A2 2 0 1 1 44 35 M56 35 A2 2 0 1 1 60 35 M45 45 A10 10 0 0 0 55 45"
                fill="none"
                stroke="#4895AA"
                strokeWidth="4"
                strokeLinecap="round"
            />

            {/* Hammer */}
            <path
                ref={hammerRef}
                d="M40 20 H60 V30 H80 V50 H20 V30 H40 V20 M50 30 V80"
                fill="#4895AA"
                stroke="black"
                strokeWidth="2"
            />
        </svg>
    );
} 